import React from "react";
import "./main.css";

const Main = () => {
  return (
    <main>
      <div className="header">
        <h1>Business Tv</h1>
        <p style={{ "font-weight": "400" }}>A TV built for your business</p>
      </div>
      <div className="owl-carousel owl-theme">
        <div className="item">
          <img
            style={{ width: "340px", objectFit: "cover" }}
            src="https://d3pbw4826yao71.cloudfront.net/010103/1623206545884_b_GU65AU7179UXZG_003_L-Perspective_Titan-Gray.jpg"
            alt=""
          />
          <div className="overlay">
            <span style={{ color: "black" }}>43"</span>
            <div>
              <h2>BE43A-H</h2>
              <p>South West Asia</p>
            </div>
          </div>
        </div>
        <div className="item">
          <img
            src="https://d3pbw4826yao71.cloudfront.net/010103/1623206546545_b_GU65AU7179UXZG_005_Dynamic1_Titan-Gray.jpg"
            alt=""
          />
          <div className="overlay">
            <span style={{ color: "black" }}>50"</span>
            <div>
              <h2>BE50A-H</h2>
              <p>South West Asia</p>
            </div>
          </div>
        </div>
        <div className="item">
          <img
            src="https://d3pbw4826yao71.cloudfront.net/010103/1623206546949_b_GU65AU7179UXZG_006_Dynamic2_Titan-Gray.jpg"
            alt=""
          />
          <div className="overlay">
            <span style={{ color: "black" }}>55"</span>
            <div>
              <h2>BE55A-H</h2>
              <p>South West Asia</p>
            </div>
          </div>
        </div>
        <div className="item">
          <img
            src="https://d3pbw4826yao71.cloudfront.net/010103/1623206850416_b_GU65AU7179UXZG_011_Front3_Titan-Gray.jpg"
            alt=""
          />
          <div className="overlay">
            <span style={{ color: "black" }}>70"</span>
            <div>
              <h2>BE70A-H</h2>
              <p>South West Asia</p>
            </div>
          </div>
        </div>
      </div>

      <ul className="main_list">
        <li>
          UHD display with HDR functionality delivers an eye-catching visual
          experience 16/7
        </li>
        <li>
          Simple, ready-to-use content templates allow users to showcase
          personalized content alongside TV programming
        </li>
        <li>
          Samsung Business TV app enables easy content management and control
        </li>
      </ul>

      <nav className="nav-sections">
        <ul className="menu">
          <li className="menu-item">
            <a className="menu-item-link active" href="#about">
              FEATURES
            </a>
          </li>
          <li className="menu-item">
            <a className="menu-item-link" href="#documentation">
              SPECS
            </a>
          </li>
          <li className="menu-item">
            <a className="menu-item-link" href="#showcase">
              RESOURCES
            </a>
          </li>

          <div className="active-line"></div>
        </ul>
      </nav>

      <main id="main-content" className="page-sections">
        <section className="page-section">
          <h2 className="section-title" id="about">
            Features
          </h2>
          <h2 className="section-title" id="about">
            A TV built for your business
          </h2>
          <img
            style={{ width: "100%" }}
            src="https://cbr.lk/wp-content/uploads/2022/08/DSCF2781.0.jpg"
            alt=""
          />
        </section>
        <section className="page-section">
          <h2 className="section-title" id="documentation">
            Specs
          </h2>

          <div className="main-spec">
            <h3 className="panel-txt">Panel</h3>
            <hr />
            <div className="detail">
              <div className="spec">
                <p className="text-style">Diagonal Size</p>
                <p>70"</p>
                <p className="text-style">Contrast Ratio</p>
                <p>4700/1</p>
                <p className="text-style">Color Gamut</p>
                <p>72%</p>
              </div>
              <div className="spec">
                <p className="text-style">Diagonal Size</p>
                <p>70"</p>
                <p className="text-style" className="text-style">
                  Contrast Ratio
                </p>
                <p>4700/1</p>
                <p className="text-style">Color Gamut</p>
                <p>72%</p>
              </div>
              <div className="spec">
                <p className="text-style">Diagonal Size</p>
                <p>70"</p>
                <p className="text-style">Contrast Ratio</p>
                <p>4700/1</p>
                <p className="text-style">Color Gamut</p>
                <p>72%</p>
              </div>
              <div className="spec">
                <p className="text-style">Diagonal Size</p>
                <p>70"</p>
                <p className="text-style">Contrast Ratio</p>
                <p>4700/1</p>
                <p className="text-style">Color Gamut</p>
                <p>72%</p>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section">
          <h2 className="section-title" id="showcase">
            Resources
          </h2>
          <div className="tabbordion">
            <input
              type="radio"
              name="tabbordion-radio"
              id="tab-0-input"
              checked
            />
            <label for="tab-0-input">COLLATERALS</label>
            <section>
              <div className="tab-content">
                <div className="tab-detail">
                  <img
                    className="rs-img"
                    src="https://d3pbw4826yao71.cloudfront.net/0203/1672809038541_retail_display_solutions_catalog_img.jpg"
                    alt=""
                  />
                  <p>E-CATALOG</p>
                  <p className="rs-1">
                    Samsung Retail Display
                    <br /> Solution
                  </p>
                  <p> Reinvent the shopping experience</p>
                </div>
                <div className="tab-detail">
                  <img
                    className="rs-img"
                    src="https://d3pbw4826yao71.cloudfront.net/0203/1672809038541_retail_display_solutions_catalog_img.jpg"
                    alt=""
                  />
                  <p>E-CATALOG</p>
                  <p className="rs-1">
                    Samsung Retail Display
                    <br /> Solution
                  </p>
                  <p> Reinvent the shopping experience</p>
                </div>
                <div>
                  <img
                    className="rs-img"
                    src="https://d3pbw4826yao71.cloudfront.net/0203/1672809038541_retail_display_solutions_catalog_img.jpg"
                    alt=""
                  />
                  <p>E-CATALOG</p>
                  <p className="rs-1">
                    Samsung Retail Display
                    <br /> Solution
                  </p>
                  <p> Reinvent the shopping experience</p>
                </div>
              </div>
            </section>

            <input
              type="radio"
              name="tabbordion-radio"
              id="tab-1-input"
              checked
            />
            <label for="tab-1-input">VIDEOS</label>
            <section>
              <div className="tab-content">
                <div className="tab-detail">
                  <img
                    className="rs-img"
                    src="https://d3pbw4826yao71.cloudfront.net/0202/1631669087008_samsung_niio_movie.jpg"
                    alt=""
                  />
                  <p>E-CATALOG</p>
                  <p className="rs-1">
                    Samsung Retail Display
                    <br /> Solution
                  </p>
                  <p> Reinvent the shopping experience</p>
                </div>
              </div>
            </section>

            <input
              type="radio"
              name="tabbordion-radio"
              id="tab-2-input"
              checked
            />
            <label for="tab-2-input">NEWS</label>
            <section>
              <p>
                Lorem ipsum dolor sit amet. In perferendis galisum id nobis
                dolores a sint nostrum aut voluptatem maiores. In quisquam
                adipisci ut odio galisum et excepturi doloremque qui sequi
                voluptas eos enim dolorem et perferendis voluptatem in quod
                neque.{" "}
              </p>
              <p>
                Nam magnam adipisci in beatae sint est accusamus voluptas sed
                velit expedita qui molestias odio ut quia voluptatem! Est
                aperiam molestiae cum reprehenderit optio aut doloremque
                voluptatibus quo molestiae officia non explicabo voluptas. Qui
                dolorum tempore quo consequatur fuga est possimus voluptas et
                repudiandae praesentium nam sunt ipsa.{" "}
              </p>
              <p>
                Aut dolorem doloribus non vero molestiae 33 consequatur aliquam
                rem culpa nulla et aliquam optio. Sit sint consequatur vel
                aspernatur quia ut quas quaerat quo sapiente nobis. Qui
                accusamus quod sed facere autem et laborum dicta aut iure magnam
                cum quos ratione aut voluptatem commodi in officiis odio. Ut
                repellat aperiam et vitae eveniet ea dignissimos vitae sit omnis
                iste id amet quod?{" "}
              </p>
            </section>
          </div>
        </section>
      </main>
    </main>
  );
};

export default Main;
