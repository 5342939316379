import React, { useState } from "react";
import "./navbar.css";
import { FaSistrix } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import image from "../../assets/images/samsung-logo.webp";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenMenu = () => {
    setIsOpen(true);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <section className="navBarSection">
      <header className="header flex">
        <div className="logoDiv">
          <a href="#" className="logo flex">
            <img src={image} alt="" />
          </a>
        </div>
        {isOpen ? (
          <div>
            <div className="navBar">
              {/* <div className="navbar-left"> */}
              <ul className="navLists flex">
                <li className="navItem">
                  <a href="#" className="navLink">
                    Products
                  </a>
                </li>
                <li className="navItem">
                  <a href="#" className="navLink">
                    Solutions
                  </a>
                </li>
                <li className="navItem">
                  <a href="#" className="navLink">
                    Industries
                  </a>
                </li>
                {/* </ul> */}
                {/* </div> */}

                {/* <div className="navbar-right"> */}
                {/* <ul className="navLists flex"> */}
                <li className="navItem">
                  <a href="#" className="navLink">
                    Showcase
                  </a>
                </li>
                <li className="navItem">
                  <a href="#" className="navLink">
                    Support
                  </a>
                </li>
                <li className="navItem">
                  <a href="#" className="navLink">
                    Partners
                  </a>
                </li>
              </ul>
              {/* </div> */}
              <div className="closeNavbar" onClick={handleCloseMenu}>
                <FaRegTimesCircle className="icon" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="navBar_1">
          {/* <div className="navbar-left"> */}
          <ul className="navLists flex">
            <li className="navItem">
              <a href="#" className="navLink">
                Products
              </a>
            </li>
            <li className="navItem">
              <a href="#" className="navLink">
                Solutions
              </a>
            </li>
            <li className="navItem">
              <a href="#" className="navLink">
                Industries
              </a>
            </li>
            {/* </ul> */}
            {/* </div> */}

            {/* <div className="navbar-right"> */}
            {/* <ul className="navLists flex"> */}
            {/* <li className="navItem">
              <a href="#" className="nav__link">
                Showcase
              </a>
            </li> */}

            <li class="navItem dropdown">
              <a class="nav__link" href="#">
                Showcase
              </a>
              <ul class="sub-nav">
                <li style={{ "font-size": "30px" }}>
                  <a class="sub-nav__link" href="#">
                    Showcase
                  </a>
                </li>
                <li>
                  <a class="sub-nav__link" href="#">
                    Featured
                  </a>
                </li>
                <li>
                  <a class="sub-nav__link" href="#">
                    Virtual Experience
                  </a>
                </li>
                <li>
                  <a class="sub-nav__link" href="#">
                    Showroom
                  </a>
                </li>
              </ul>
            </li>

            <li className="navItem">
              <a href="#" className="navLink">
                Support
              </a>
            </li>
            <li className="navItem">
              <a href="#" className="navLink">
                Partners
              </a>
            </li>
          </ul>
        </div>
        <div className="header_search">
          <input
            className="header_searchInput"
            type="text"
            placeholder="Search.."
          />
          <FaSistrix className="header_searchIcon" />
        </div>
        <div className="icons">
          <a href="#" className="logo flex">
            <h1>
              <FaUserAlt className="header_userIcon" />
            </h1>
          </a>
        </div>
        <div className="burgerNavbar" onClick={handleOpenMenu}>
          <FaBars className="icon" />
        </div>
      </header>
    </section>
  );
};

export default Navbar;
